import React, { useState } from "react";
import "./Allmaids.css";
import MaidsCards from "./MaidsCards";



export default function Allmaids({ data }) {
  const [showFilipino, setShowFilipino] = useState(false);
  const [showIndo, setShowIndo] = useState(false);
  const [showEthiopia, setShowEthiopia] = useState(false);
  const [showMyanmar, setShowMyanmar] = useState(false);
  const [showNebalAnnIndiaSerlinka, setShowNebalAnnIndiaSerlinka] = useState(false);
  const [showGhanaKenyaTanzania, setShowGhanaKenyaTanzania] = useState(true);
  const [showMingHwee, setShowMingHwee] = useState(false);
  const [showMadagascar, setShowMadagascar] = useState(false);
  const [showSri_Lanka , setShowSri_Lanka]=useState(false);
  const [setSelectedMaid] = useState(null);



  const handleButtonForMyanmar = () => {
    setShowMyanmar(!showMyanmar);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowFilipino(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false);
    setShowMadagascar(false)
    setShowSri_Lanka(false);
  };

  const handleButtonForFilipino = () => {
    setShowFilipino(!showFilipino);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false);
    setShowMadagascar(false);
    setShowSri_Lanka(false);
  };

  const handleButtonForIndo = () => {
    setShowIndo(!showIndo);
    setShowFilipino(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false);
    setShowMadagascar(false)
    setShowSri_Lanka(false);
  };

  const handleButtonForEthiopian = () => {
    setShowEthiopia(!showEthiopia);
    setShowFilipino(false);
    setShowIndo(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false);
    setShowMadagascar(false)
    setShowSri_Lanka(false);
  };

  const handleButtonForNebalAnnIndiaSerlinka = () => {
    setShowNebalAnnIndiaSerlinka(!showNebalAnnIndiaSerlinka);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false);
    setShowMadagascar(false)
    setShowSri_Lanka(false);
  };

  const handleButtonForGhanaKenyaTanzania = () => {
    setShowGhanaKenyaTanzania(!showGhanaKenyaTanzania);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowMingHwee(false);
    setShowMadagascar(false)
    setShowSri_Lanka(false);
  };

  const handleButtonForMingHwee = () => {
    setShowMingHwee(!showMingHwee);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMadagascar(false)
    setShowSri_Lanka(false);
  };


  const hanldButtonForMadagascar =() =>{
    setShowMadagascar(!showMadagascar);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false)
    setShowSri_Lanka(false);


  }

  const handleButtonForSri_Lanka = ()=>{
    setShowSri_Lanka(!showSri_Lanka);
    setShowMadagascar(false);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowMingHwee(false)


  }

  const filipinoMaids = data
  .filter(function (maid) {
    return (
      maid.nationality.includes("Philippines") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty")&&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")           
    );
  })
  .sort(function (a, b) {
    const agencyA = a.agency.toUpperCase();
    const agencyB = b.agency.toUpperCase();
    const mingString = "MING HWEE MANPOWER  RECRUITMENT INC"

    if (agencyA === agencyB) {
      return 0;
    } else if (
      agencyA.includes(mingString.slice(0,3))
    ) {
      return -1;
    } else if (
      agencyB.includes(mingString.slice(0,3))
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  const indonesianMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Indonesia") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty") &&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const ethiopianMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Ethiopia") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty") &&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const myanmarMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Myanmar") && 
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty")&&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const MadagascarMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Madagascar") && 
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty")&&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );


  const nebalAnnIndiaSerlinkaMaids = data.filter(
    (maid) => {
      const meetsNationalityCriteria =
        maid.nationality.includes('Nepal') ||
        maid.nationality.includes("Indian");

      const NotAvilable = !maid?.maid_booked?.includes("Booked") && !maid?.maid_booked?.includes("Empty") && !maid?.maid_booked?.includes("Hold") && !maid?.visa_status?.includes("Visa") ;
      return meetsNationalityCriteria && NotAvilable;
    }
  );

  const ghanaKenyaTanzaniaMaids = data.filter(
    (maid) => {
      const meetsNationalityCriteria =
        maid.nationality.includes('Ghana') ||
        maid.nationality.includes("Kenya") ||
        maid.nationality.includes("Uganda")||
        maid.nationality.includes("Tanzania")

      const NotAvilable = !maid?.maid_booked?.includes("Booked") && !maid?.maid_booked?.includes("Empty") && !maid?.maid_booked?.includes("Hold") && !maid?.visa_status?.includes("Visa") ;
      return meetsNationalityCriteria && NotAvilable;
    }
  );

  const mingHweeMaids =  data.filter(
    
    (maid) =>
      maid.agency.includes("MING HWEE MANPOWER  RECRUITMENT INC/ MD".slice(0,3)) &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty")&&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const handleMaidClick = (maidName) => {
    setSelectedMaid(maidName);
  };

  const sri_linka =  data.filter(
    (maid) =>
      maid.nationality.includes("Sri_Lanka") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty") &&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  return (
      <div className='' style={{ backgroundColor: '#F0F0F0', borderRadius: '70px' }}>
            <div className="button-container">
              {filipinoMaids.length > 0 && (
                <button
                  className={`btn ${showFilipino ? "active" : ""}`}
                  onClick={handleButtonForFilipino}
                >
                  <span className="nationality">Filipina</span>{" "}
                  <span className="circle">{filipinoMaids.length}</span>
                </button>
              )}

              {ethiopianMaids.length > 0 && (
                <button
                  className={`btn ${showEthiopia ? "active" : ""}`}
                  onClick={handleButtonForEthiopian}
                >
                  <span className="nationality">Ethiopia </span>{" "}
                  <span className="circle">{ethiopianMaids.length}</span>
                </button>
              )}

              {indonesianMaids.length > 0 && (
                <button
                  className={`btn ${showIndo ? "active" : ""}`}
                  onClick={handleButtonForIndo}
                >
                  <span className="nationality">Indonesian</span>{" "}
                  <span className="circle">{indonesianMaids.length}</span>
                </button>
              )}

              {myanmarMaids.length > 0 && (
                <button
                  className={`btn ${showMyanmar ? "active" : ""}`}
                  onClick={handleButtonForMyanmar}
                >
                  <span className="nationality">Myanmar</span>{" "}
                  <span className="circle">{myanmarMaids.length}</span>
                </button>
              )}

              {nebalAnnIndiaSerlinkaMaids.length > 0 && (
                <button
                  className={`btn ${showNebalAnnIndiaSerlinka ? "active" : ""}`}
                  onClick={handleButtonForNebalAnnIndiaSerlinka}
                >
                  <span className="nationality">Others</span>{" "}
                  <span className="circle">{nebalAnnIndiaSerlinkaMaids.length}</span>
                </button>
              )}

              {ghanaKenyaTanzaniaMaids.length > 0 && (
                <button
                  className={`btn ${showGhanaKenyaTanzania ? "active" : ""}`}
                  onClick={handleButtonForGhanaKenyaTanzania}
                >
                  <span className="nationality"> Africa</span>{" "}
                  <span className="circle">{ghanaKenyaTanzaniaMaids.length}</span>
                </button>
              )}

          {MadagascarMaids.length > 0 && (
                <button
                  className={`btn ${showMadagascar ? "active" : ""}`}
                  onClick={hanldButtonForMadagascar}
                >
                  <span className="nationality">Madagascar</span>{" "}
                  <span className="circle">{MadagascarMaids.length}</span>
                </button>
              )}

              {mingHweeMaids.length > 0 && (
                <button
                  className={`btn ${showMingHwee ? "active" : ""}`}
                  onClick={handleButtonForMingHwee}
                >
                  <span className="nationality">Exp-Filipina</span>{" "}
                  <span className="circle">{mingHweeMaids.length}</span>
                </button>
              )}

          {sri_linka.length > 0 && (
                <button
                  className={`btn ${showSri_Lanka ? "active" : ""}`}
                  onClick={handleButtonForSri_Lanka}
                >
                  <span className="nationality">Sri_Lanka</span>{" "}
                  <span className="circle">{sri_linka.length }</span>
                </button>
              )}
            </div>
        
        {showFilipino && filipinoMaids.length > 0 ? (
          <MaidsCards 
            data={filipinoMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : showIndo && indonesianMaids.length > 0 ? (
          <MaidsCards 
            data={indonesianMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : showEthiopia && ethiopianMaids.length > 0 ? (
          <MaidsCards 
            data={ethiopianMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : showMyanmar && myanmarMaids.length > 0 ? (
          <MaidsCards 
            data={myanmarMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : showNebalAnnIndiaSerlinka && nebalAnnIndiaSerlinkaMaids.length > 0 ? (
          <MaidsCards 
            data={nebalAnnIndiaSerlinkaMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : showGhanaKenyaTanzania && ghanaKenyaTanzaniaMaids.length > 0 ? (
          <MaidsCards 
            data={ghanaKenyaTanzaniaMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : showMingHwee && mingHweeMaids.length > 0 ? (
          <MaidsCards 
            data={mingHweeMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : 

          showMadagascar && MadagascarMaids.length > 0 ? (
          <MaidsCards 
            data={MadagascarMaids}
            handleMaidClick={handleMaidClick}
          />
        ) : 
        
        showSri_Lanka && sri_linka.length > 0 ? (
          <MaidsCards 
            data={sri_linka}
            handleMaidClick={handleMaidClick}
          />
        ) : 
        
        (
          <p>Select Nationality.</p>
        )}
    

    </div>
   
  );
}
